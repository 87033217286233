<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const title_rendered = computed(() => renderRichText(props.blok.title));
</script>

<template>
    <section class="bg-white dark:bg-midnight-blue">
        <div class="mx-4 py-24 md:mx-12">
            <div
                class="mx-auto max-w-screen-xl md:grid md:grid-cols-2 bg-gradient-to-r to-gray-50 from-gray-200 dark:from-midnight-blue dark:to-deep-blue rounded-3xl relative overflow-hidden"
            >
                <div class="my-auto items-center py-12 md:py-16 px-12 md:px-16">
                    <h2
                        class="mb-4 text-4xl lg:text-5xl text-gray-900 dark:text-white"
                        v-html="title_rendered"
                    ></h2>
                    <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
                        {{ blok.description }}
                    </p>
                    <div class="items-center flex space-y-0 space-x-4">
                        <StoryblokComponent
                            v-for="item in blok.ctas"
                            :key="item._uid"
                            :blok="item"
                        />
                    </div>
                </div>
                <div class="h-60 md:h-full">
                    <img
                        class="mx-auto md:flex w-full md:h-full object-cover object-center"
                        :src="`${blok.image.filename}/m/800x0`"
                        alt="mobile app"
                    />
                </div>
            </div>
        </div>
    </section>
</template>